import { iframeResizer } from 'iframe-resizer';

export const initializeIframeResizer = () => {
  if (window.parentIFrame) {
    window.parentIFrame.autoResize(true);
    
    // Trigger resize on initial load
    window.parentIFrame.size();

    // Trigger resize on window resize
    window.addEventListener('resize', () => {
      window.parentIFrame.size();
    });

    // Trigger resize when content changes
    const observer = new MutationObserver(() => {
      window.parentIFrame.size();
    });

    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true
    });
  }
};
