import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CalculatorForm from './CalculatorForm';
import { initializeIframeResizer } from './utils/iframeResizer';

const App = () => {
  useEffect(() => {
    initializeIframeResizer();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<CalculatorForm />} />
      </Routes>
    </Router>
  );
}

export default App;
