import React, { useState, useEffect, useRef } from 'react';
import { FaIndustry, FaMapMarkerAlt, FaTachometerAlt, FaPlug, FaClock, FaLeaf,  FaRegBuilding, FaBolt, FaSpinner, FaExclamationCircle } from 'react-icons/fa';
import { FaInfoCircle } from 'react-icons/fa';
//import DetailedReportForm from './DetailedReportForm';

import { v4 as uuidv4 } from 'uuid';
import { initializeIframeResizer } from './utils/iframeResizer';

const CalculatorForm = () => {
  const [errors, setErrors] = useState({});
  const [showErrorBubble, setShowErrorBubble] = useState(false);
  const errorBubbleRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formSubmitted, setFormSubmitted] = useState(false);
  useEffect(() => {
    // Generate a session ID if it doesn't exist
    if (!localStorage.getItem('sessionId')) {
      localStorage.setItem('sessionId', uuidv4());
    }
    // Check if detailed report has been submitted before
    const detailedReportSubmittedBefore = localStorage.getItem('detailedReportSubmitted') === 'true';
    setDetailedReportSubmitted(detailedReportSubmittedBefore);

    // Initialize iframe resizer
    initializeIframeResizer();

    // Handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [detailedFormData, setDetailedFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    gdprAgreement: false,
    sessionId: ''
  });
  const [emailError, setEmailError] = useState('');

  const personalEmailDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'icloud.com', 'aol.com', 'outlook.com', 'live.com', 'msn.com', 'protonmail.com', 'yandex.com', 'mail.com', 'zoho.com', 'gmx.com', 'fastmail.com'];

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    const domain = email.split('@')[1];
    return !personalEmailDomains.includes(domain);
  };

  const handleDetailedFormChange = (e) => {
    const { name, value } = e.target;
    setDetailedFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleDetailedReportSubmit = async (e) => {
    e.preventDefault();
    if (isValidEmail(detailedFormData.email) && detailedFormData.gdprAgreement) {
      setIsSubmitting(true);
      const submissionData = {
        ...detailedFormData,
        sessionId: localStorage.getItem('sessionId')
      };
      console.log('Submitting detailed form data:', submissionData);
      try {
        // Immediately set the form as submitted
        setDetailedReportSubmitted(true);
        localStorage.setItem('detailedReportSubmitted', 'true');
        
        // Then send the data to the server
        const response = await fetch('/detailed-report', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submissionData),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Detailed report request logged:', data);
      } catch (error) {
        console.error('Error logging detailed report request:', error);
        // Even if there's an error, we don't change the UI state back
        // The user has already seen the success message
      } finally {
        setIsSubmitting(false);
      }
    } else {
      if (!isValidEmail(detailedFormData.email)) {
        setEmailError('Please use a corporate email address.');
      }
      if (!detailedFormData.gdprAgreement) {
        setEmailError('You must agree to the GDPR terms.');
      }
    }
  };

  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [energyCarbonCostData] = useState({});
  const [showDetailedReportForm, setShowDetailedReportForm] = useState(false);
  const [plantSizeOptions, setPlantSizeOptions] = useState([]);
  const [detailedReportSubmitted, setDetailedReportSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [formData, setFormData] = useState({
    projectName: '',
    industry: '',
    plantSize: '',
    siteLocation: '',
    paybackTime: 1,
    numberOfInstalledPumps: '',
    averagePowerPerPump: '',
    yearlyAverageOperatingHours: 8500,
    estimatedCostOfEnergy: '',
    estimatedCostOfCarbon: '',
    assetSelection: 'Customer with Flowserve',
    typeOfProgram: 'Full Program',
    serviceFactor: 0.8,
    sparedOperation: true,
    estimatedCarbonIntensity: '',
  });
  const initialFormData = {
    projectName: '',
    industry: '',
    plantSize: '',
    siteLocation: '',
    paybackTime: 1,
    numberOfInstalledPumps: '',
    averagePowerPerPump: '',
    yearlyAverageOperatingHours: 8500,
    estimatedCostOfEnergy: '',
    estimatedCostOfCarbon: '',
    assetSelection: 'Customer with Flowserve',
    typeOfProgram: 'Full Program',
    serviceFactor: 0.8,
    sparedOperation: true,
    estimatedCarbonIntensity: '',
  };
  const [showAdvancedControls, setShowAdvancedControls] = useState(false);
  const [results, setResults] = useState(null);
  const [manuallyUpdated, setManuallyUpdated] = useState({
    estimatedCostOfEnergy: false,
    estimatedCostOfCarbon: false,
  });
  const euroUsingCountries = new Set([
    'Austria', 'Belgium', 'Cyprus', 'Estonia', 'Finland', 'France', 'Germany',
    'Greece', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta',
    'Netherlands', 'Portugal', 'Slovakia', 'Slovenia', 'Spain', 'All Europe',
  ]);

  useEffect(() => {
    if (detailedReportSubmitted) {
      setDetailedFormData({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        gdprAgreement: false
      });
    }
  }, [detailedReportSubmitted]);

  useEffect(() => {
    // Update plant size options when industry changes
    if (formData.industry) {
      let options = [];
      if (formData.industry === 'Oil Refinery') {
        options = [
          'Select Plant Size',
          '<100,000 bpd',
          '100,000 - 400,000 bpd',
          '400,000 - 800,000 bpd',
          '>800,000 bpd',
        ];
      } else if (formData.industry === 'Petrochemical') {
        options = [
          'Select Plant Size',
          'Small',
          'Medium',
          'Large',
          'Extra Large',
        ];
      }
      setPlantSizeOptions(options);
    } else {
      setPlantSizeOptions([]);
    }
  }, [formData.industry]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;

    // Prevent negative values for numeric fields
    if (type === 'number' && parseFloat(newValue) < 0) {
      newValue = '0';
    }

    if (name === 'industry' && formData.industry !== newValue) {
      setFormData(prev => ({
        ...prev,
        numberOfInstalledPumps: '',
        averagePowerPerPump: '',
        [name]: newValue,
      }));
      setManuallyUpdated((prevState) => ({
        ...prevState,
        estimatedCostOfEnergy: false,
        estimatedCostOfCarbon: false,
      }));
    } else {
      if (name === 'industry') {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: newValue,
          numberOfInstalledPumps: '',
          averagePowerPerPump: '',
        }));
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: newValue,
        }));
      }
    }
    if (name === 'industry') {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: newValue,
        numberOfInstalledPumps: '',
        averagePowerPerPump: '',
      }));
      setManuallyUpdated(prevManuallyUpdated => ({
        ...prevManuallyUpdated,
        estimatedCostOfEnergy: false,
        estimatedCostOfCarbon: false,
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: newValue,
      }));
      if (name === 'siteLocation' && euroUsingCountries.has(newValue)) {
        setCurrencySymbol('€');
      }
    }

    if (name === 'siteLocation' && euroUsingCountries.has(newValue)) {
      setCurrencySymbol('€');
    } else if (name === 'siteLocation') {
      setCurrencySymbol('$');
    } else if (name === 'plantSize') {
      switch (value) {
        case '<100,000 bpd':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 750, averagePowerPerPump: 60 }));
          break;
        case '100,000 - 400,000 bpd':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 1250, averagePowerPerPump: 70 }));
          break;
        case '400,000 - 800,000 bpd':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 2500, averagePowerPerPump: 80 }));
          break;
        case '>800,000 bpd':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 3500, averagePowerPerPump: 100 }));
          break;
        case 'Small':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 50, averagePowerPerPump: 40 }));
          break;
        case 'Medium':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 100, averagePowerPerPump: 40 }));
          break;
        case 'Large':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 500, averagePowerPerPump: 40 }));
          break;
        case 'Extra Large':
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: 1000, averagePowerPerPump: 40 }));
          break;
        default:
          setFormData(prev => ({ ...prev, numberOfInstalledPumps: '', averagePowerPerPump: '' }));
          break;
      }
    }

    if (name === 'estimatedCostOfEnergy' || name === 'estimatedCostOfCarbon') {
      setManuallyUpdated((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
  };

  const [tooltipVisible, setTooltipVisible] = useState(null);

  const tooltips = {
    paybackTime: "Payback time on the investment required to improve plant economics. If unknown, use the default value.",
    numberOfInstalledPumps: "Estimated number of pumps installed at the plant. If unknown, use the default value.",
    averagePowerPerPump: "Average absorbed electric power. If unknown, use the default value.",
    yearlyAverageOperatingHours: "Average yearly operating hours for each pump. If unknown, use the default value.",
    estimatedCostOfEnergy: "If unknown, use the default based on your plant's location.",
    estimatedCostOfCarbon: "If unknown, use the default based on your plant's location.",
    assetSelection: "Who will be selecting the assets for the plant? For the highest ROI, select \"Jointly with Flowserve.\" Otherwise, select \"Customer Alone.\"",
    typeOfProgram: "Work on all the pumps or select the greatest consumers in the plant.",
    serviceFactor: "Nominal to installed power ratio (value between 0 and 1). If unknown, use the default of 0.8.",
    sparedOperation: "Select yes if your pumps are backed by a spare unit.",
    estimatedCarbonIntensity: "Measure of how much carbon is generated for the available power source. If a grid has a majority of coal-produced power, the ratio of carbon intensity will be higher versus a grid with more green sources of power generation such as Nuclear and wind. If unknown, leave blank.",
  };

  const handleTooltipVisible = (e, tooltipName) => {
    e.preventDefault();
    setTooltipVisible(tooltipName);
  };

  const handleHideTooltip = () => {
    setTooltipVisible(null);
  };


  const fetchEnergyCarbonCostData = async () => {
    if (formData.siteLocation && !manuallyUpdated.estimatedCostOfEnergy && !manuallyUpdated.estimatedCostOfCarbon) {
      try {
        const response = await fetch(`/energy-carbon-cost?location=${encodeURIComponent(formData.siteLocation)}`);
        const data = await response.json();
        setFormData((prevFormData) => ({
          ...prevFormData,
          estimatedCostOfEnergy: data.energyCost || '',
          estimatedCostOfCarbon: data.costOfCarbon || '',
        }));
      } catch (error) {
        console.error('Error fetching energy and carbon cost data:', error);
      }
    }
  };

  useEffect(() => {
    if (formData.siteLocation) {
      // Reset manually updated flags when location changes
      setManuallyUpdated({
        estimatedCostOfEnergy: false,
        estimatedCostOfCarbon: false
      });
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log('Selected site location:', formData.siteLocation);
        console.log('Making request to /energy-carbon-cost');
      }
      fetchEnergyCarbonCostData();
    }
  }, [formData.siteLocation]);

  useEffect(() => {
    // Only reset results if formData changes significantly
    if (formData.projectName || formData.industry || formData.plantSize || formData.siteLocation) {
      setResults(null);
    }
  }, [formData]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid business email address.');
      return;
    }
    const sessionId = localStorage.getItem('sessionId');
    const calculatorSubmission = {
      ...formData,
      results: results
    };
    console.log('Submitting email:', { email, sessionId, calculatorSubmission });

    try {
      // Immediately update UI
      setShowEmailInput(false);
      setEmailSubmitted(true);
      setEmailError('');

      // Then send the data to the server asynchronously
      const response = await fetch('/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, sessionId, calculatorSubmission }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Email submission logged:', data);
    } catch (error) {
      console.error('Error logging email submission:', error);
      // Note: We're not reverting the UI state here, as the user has already seen the success message
    }
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  const newErrors = {};
  const requiredFields = ['industry', 'plantSize', 'siteLocation', 'numberOfInstalledPumps', 'estimatedCostOfEnergy', 'estimatedCostOfCarbon', 'averagePowerPerPump', 'yearlyAverageOperatingHours'];
      
  let hasEmptyFields = false;
  let hasAdvancedErrors = false;
  requiredFields.forEach(field => {
    if (!formData[field]) {
      newErrors[field] = 'This field is required';
      hasEmptyFields = true;
      if (['averagePowerPerPump', 'yearlyAverageOperatingHours'].includes(field)) {
        hasAdvancedErrors = true;
      }
    }
  });

  setErrors(newErrors);

  if (hasAdvancedErrors) {
    setShowAdvancedControls(true);
  }

  if (!hasEmptyFields) {
    // Scroll to top on mobile devices
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const sessionId = localStorage.getItem('sessionId');
    const submissionData = {
      ...formData,
      currencySymbol,
      sessionId,
    };
    try {
      const response = await fetch('/calculate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();
      setResults(data);
      setDetailedFormData((prevFormData) => ({
        ...prevFormData,
        sessionId: sessionId
      }));
      if (email) {
        submissionData.email = email;
      }
      setShowResultsPopup(true);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
    }
  } else {
    // Show error bubble
    setShowErrorBubble(true);
    // Scroll to the top of the form
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
  const handleClear = () => {
    setFormData(initialFormData);
  };



  useEffect(() => {
    if (showErrorBubble) {
      const timer = setTimeout(() => {
        setShowErrorBubble(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showErrorBubble]);

  return (
    <div className="min-h-screen bg-gray-50 text-gray-700 input-bg-light py-8 px-4 sm:px-6 md:px-8 lg:px-10">
      <div className="container mx-auto py-4 max-w-screen-xl">
        {showErrorBubble && (
          <div ref={errorBubbleRef} className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> Please fill in all required fields.</span>
            <FaExclamationCircle className="absolute top-0 right-0 mt-3 mr-4" />
          </div>
        )}
        {(!isMobile || (isMobile && formSubmitted)) && (
          <div className="mt-2">
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-4">
              <div className="bg-green-500 rounded-lg text-2xl py-8 px-4 text-white shadow">
                <p className="text-white text-xs">Total Annual Savings</p>
                <p className="font-bold text-2xl">{currencySymbol}{results && results.totalAnnualSavings ? formatNumber(results.totalAnnualSavings) : '----'}</p>
              </div>
              <div className="bg-white py-8 px-4 rounded-lg shadow">
                <p className="text-gray-700 text-xs">Energy Savings</p>
                <p className="font-bold text-2xl">{results ? Math.round(results.energySavings / 1000).toLocaleString() : '----'}</p>
                <p className="text-gray-700 text-sm">(MWh/year)</p>
              </div>
              <div className="bg-white py-8 px-4 rounded-lg shadow">
                <p className="text-gray-700 text-xs">CO2 Saved</p>
                <p className="font-bold text-2xl">{results ? Math.round(results.co2Saved).toLocaleString() : '----'}</p>
                <p className="text-gray-700 text-sm">(tons/year)</p>
              </div>
              <div className="bg-white py-8 px-4 rounded-lg shadow">
                <p className="text-gray-700 text-xs">Operating Annual Savings</p>
                <div className={`${results && (detailedReportSubmitted || emailSubmitted) ? '' : 'blur-effect'}`}>
                  <p className="font-bold text-2xl">{currencySymbol}{results ? results.operatingAnnualSavings : '****'}</p>
                </div>
              </div>
              <div className="bg-white py-8 px-4 rounded-lg shadow">
                <p className="text-gray-700 text-xs">Savings from CO2 Reduction</p>
                <div className={`${results && (detailedReportSubmitted || emailSubmitted) ? '' : 'blur-effect'}`}>
                  <p className="font-bold text-2xl">{currencySymbol}{results ? results.savingsFromCo2Reduction : '****'}</p>
                </div>
              </div>
              <div className="bg-white py-8 px-4 rounded-lg shadow">
                <p className="text-gray-700 text-xs">Expected ROI</p>
                <div className={`${results && (detailedReportSubmitted || emailSubmitted) ? '' : 'blur-effect'}`}>
                  <p className="font-bold text-2xl">{results ? `${parseFloat(results.roi).toFixed(2)}%` : '****'}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {results && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-4">
            {!detailedReportSubmitted && (
              <div className="flex justify-start">
                <button
                  onClick={() => {
                    console.log('Get Detailed Report button clicked');
                    setShowDetailedReportForm(true);
                  }}
                  className="w-full sm:w-auto px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                  style={{ alignSelf: 'flex-start' }}
                >
                  Get Detailed Report
                </button>
              </div>
            )}
            <div className="flex flex-col items-end">
              {!emailSubmitted && !detailedReportSubmitted && (
                <>
                  <button
                    onClick={() => setShowEmailInput(!showEmailInput)}
                    className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{ alignSelf: 'flex-end' }}
                  >
                    Show Hidden Savings
                  </button>
                  {showEmailInput && (
                    <form onSubmit={handleEmailSubmit} className="mt-2 w-full flex flex-col items-end" style={{ alignSelf: 'flex-end' }}>
                      <div className="w-full flex items-center">
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                          placeholder="Enter your email"
                          required
                        />
                        <button
                          type="submit"
                          className="ml-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                        >
                          Submit
                        </button>
                      </div>
                      {emailError && (
                        <p className="text-red-500 text-sm mt-1">{emailError}</p>
                      )}
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl mb-4">General Info</h2>
            {/*
            <div className="mb-4">
              <label htmlFor="projectName" className="block mb-2">
                <FaProjectDiagram className="inline-block mr-2" />
                Project Name
              </label>
              <input
                type="text"
                id="projectName"
                name="projectName"
                value={formData.projectName}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.siteLocation ? 'border-2 border-red-500' : ''}`}
              />
            </div>
            */}
            <div className="mb-4">
                <label htmlFor="industry" className="block mb-2">
                  <FaRegBuilding className="inline-block mr-2" />
                  Industry
                </label>
                <select
                  id="industry"
                  name="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.industry ? 'border-2 border-red-500' : ''}`}
                >
                  <option value="">Select Industry</option>
                  <option value="Oil Refinery">Oil Refinery</option>
                  <option value="Petrochemical">Petrochemical</option>
                </select>
                {errors.industry && <p className="text-red-500 text-sm mt-1">{errors.industry}</p>}
            </div>

            <div className="mb-4">
              <label htmlFor="plantSize" className="block mb-2">
                <FaIndustry className="inline-block mr-2" />
                Plant Size
              </label>
              <select
                id="plantSize"
                name="plantSize"
                value={formData.plantSize}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.plantSize ? 'border-2 border-red-500' : ''}`}
              >
                {plantSizeOptions.map(size => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </select>
              {errors.plantSize && <p className="text-red-500 text-sm mt-1">{errors.plantSize}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="siteLocation" className="block mb-2">
                <FaMapMarkerAlt className="inline-block mr-2" />
                Site Location
              </label>
              <select
                id="siteLocation"
                name="siteLocation"
                value={formData.siteLocation}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.siteLocation ? 'border-2 border-red-500' : ''}`}
              >
                <option value="">Select Site Location</option>
                <option value="All Europe">All Europe</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Canada">Canada</option>
                <option value="China">China</option>
                <option value="Denmark">Denmark</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Ireland">Ireland</option>
                <option value="Japan">Japan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Lichtenstein">Lichtenstein</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Mexico">Mexico</option>
                <option value="Netherlands">Netherlands</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Other South America">Other South America</option>
                <option value="Portugal">Portugal</option>
                <option value="Qatar">Qatar</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovenia">Slovenia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Korea">South Korea</option>
                <option value="Spain">Spain</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="Uruguay">Uruguay</option>
              </select>
            </div>
            {/*
            <div className="mb-4 relative">
              <label htmlFor="paybackTime" className="block mb-2">
                <FaCalendarAlt className="inline-block mr-2" />
                Payback Time (Years)
                <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'paybackTime')} onMouseLeave={handleHideTooltip} />
              </label>
              <input
                type="number"
                id="paybackTime"
                name="paybackTime"
                value={formData.paybackTime}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.estimatedCostOfEnergy ? 'border-2 border-red-500' : ''}`}

              />
              {tooltipVisible === 'paybackTime' && (
                <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.paybackTime}</div>
              )}
            </div>
            */}
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl mb-4">Plant Description</h2>
            <div className="mb-4 relative">
              <label htmlFor="numberOfInstalledPumps" className="block mb-2">
                <FaTachometerAlt className="inline-block mr-2" />
                Number of Installed Pumps
                <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'numberOfInstalledPumps')} onMouseLeave={handleHideTooltip} />
              </label>
              <input
                type="number"
                id="numberOfInstalledPumps"
                name="numberOfInstalledPumps"
                value={formData.numberOfInstalledPumps}
                onChange={handleChange}
                max="10000"
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.numberOfInstalledPumps ? 'border-2 border-red-500' : ''}`}
              />
              {tooltipVisible === 'numberOfInstalledPumps' && (
                <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.numberOfInstalledPumps}</div>
              )}
            </div>
            <div className="mb-4 relative">
              <label htmlFor="estimatedCostOfEnergy" className="block mb-2">
                <FaBolt className="inline-block mr-2" />
                Estimated Cost of Energy ({currencySymbol}/kWh)
                <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'estimatedCostOfEnergy')} onMouseLeave={handleHideTooltip} />
              </label>
              <input
                type="number"
                id="estimatedCostOfEnergy"
                name="estimatedCostOfEnergy"
                step="0.001"
                min="0.000"
                max="5.000"
                value={formData.estimatedCostOfEnergy}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.estimatedCostOfEnergy ? 'border-2 border-red-500' : ''}`}
              />
              {tooltipVisible === 'estimatedCostOfEnergy' && (
                <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.estimatedCostOfEnergy}</div>
              )}
            </div>
            <div className="mb-4 relative">
              <label htmlFor="estimatedCostOfCarbon" className="block mb-2">
                <FaLeaf className="inline-block mr-2" />
                Estimated Cost of Carbon ({currencySymbol}/Ton CO2)
                <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'estimatedCostOfCarbon')} onMouseLeave={handleHideTooltip} />
              </label>
              <input
                type="number"
                id="estimatedCostOfCarbon"
                name="estimatedCostOfCarbon"
                min="0"
                max="1000"
                step="0.01"
                value={formData.estimatedCostOfCarbon}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.estimatedCostOfCarbon ? 'border-2 border-red-500' : ''}`}
              />
              {tooltipVisible === 'estimatedCostOfCarbon' && (
                <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.estimatedCostOfCarbon}</div>
              )}
            </div>
          </div>
          <div className="md:col-span-1 flex flex-col md:flex-row justify-start space-y-4 md:space-y-0 md:space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Calculate
            </button>
            <button
              type="button"
              onClick={() => setShowAdvancedControls(!showAdvancedControls)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {showAdvancedControls ? 'Hide Advanced Controls' : 'Show Advanced Controls'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
          {showAdvancedControls && (
          <>
            <div className="bg-white rounded-lg shadow-md p-6 md:col-span-2">
              <h2 className="text-xl mb-4 md:col-span-2">Advanced Controls</h2>
              <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="mb-4 relative">
                  <label htmlFor="averagePowerPerPump" className="block mb-2">
                    <FaPlug className="inline-block mr-2" />
                    {formData.siteLocation === 'United States' 
                    ? 'Average Power per Pump (HP)' 
                    : 'Average Power per Pump (kW)'
                  }
                    <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'averagePowerPerPump')} onMouseLeave={handleHideTooltip} />
                  </label>
                  <input
                    type="number"
                    id="averagePowerPerPump"
                    name="averagePowerPerPump"
                    min="0"
                    max="999"
                    value={formData.averagePowerPerPump}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.averagePowerPerPump ? 'border-2 border-red-500' : ''}`}
                  />
                  {tooltipVisible === 'averagePowerPerPump' && (
                    <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.averagePowerPerPump}</div>
                  )}
                </div>
                <div className="mb-4 relative">
                  <label htmlFor="yearlyAverageOperatingHours" className="block mb-2">
                    <FaClock className="inline-block mr-2" />
                    Yearly Average Operating Hours
                    <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'yearlyAverageOperatingHours')} onMouseLeave={handleHideTooltip} />
                  </label>
                  <input
                    type="number"
                    id="yearlyAverageOperatingHours"
                    name="yearlyAverageOperatingHours"
                    min="0"
                    max="8784"
                    value={formData.yearlyAverageOperatingHours}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 ${errors.yearlyAverageOperatingHours ? 'border-2 border-red-500' : ''}`}
                  />
                  {tooltipVisible === 'yearlyAverageOperatingHours' && (
                    <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.yearlyAverageOperatingHours}</div>
                  )}
                </div>
              <div className="mb-4 relative">
                <label htmlFor="estimatedCarbonIntensity" className="block mb-2">
                  <FaLeaf className="inline-block mr-2" />
                  Estimated Carbon Intensity (CO2/kW)
                  <FaInfoCircle className="inline-block ml-2 text-blue-500 cursor-pointer" onMouseEnter={(e) => handleTooltipVisible(e, 'estimatedCarbonIntensity')} onMouseLeave={handleHideTooltip} />
                </label>
                <input
                  type="number"
                  id="estimatedCarbonIntensity"
                  name="estimatedCarbonIntensity"
                  step="0.00001"
                  min="0"
                  max="1.0"
                  value={formData.estimatedCarbonIntensity}
                  onChange={handleChange}
                  className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                />
                {tooltipVisible === 'estimatedCarbonIntensity' && (
                  <div className="absolute left-0 mt-2 p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">{tooltips.estimatedCarbonIntensity}</div>
                )}
              </div>
              </div>
            </div>
          </>
        )}


        </form>

      </div>

      {showDetailedReportForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-screen-lg md:max-w-3/4">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="w-full md:w-1/2">
                <h2 className="text-xl font-bold mb-4">Get Detailed Report</h2>
                {detailedReportSubmitted ? (
                  <div className="text-center">
                    <h2 className="text-xl font-bold mb-4">Success!</h2>
                    <p>We will be in touch soon.</p>
                    <button
                      type="button"
                      onClick={() => setShowDetailedReportForm(false)}
                      className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <>
                    <form onSubmit={handleDetailedReportSubmit}>
                      {emailError && (
                        <div className="mb-4 text-red-500">
                          {emailError}
                        </div>
                      )}
                      <div className="mb-4">
                        <label htmlFor="company" className="block mb-2">Company</label>
                        <input
                          type="text"
                          id="company"
                          name="company"
                          value={detailedFormData.company}
                          onChange={handleDetailedFormChange}
                          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="firstName" className="block mb-2">First Name</label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={detailedFormData.firstName}
                          onChange={handleDetailedFormChange}
                          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="lastName" className="block mb-2">Last Name</label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={detailedFormData.lastName}
                          onChange={handleDetailedFormChange}
                          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="email" className="block mb-2">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={detailedFormData.email}
                          onChange={handleDetailedFormChange}
                          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            id="gdprAgreement"
                            name="gdprAgreement"
                            checked={detailedFormData.gdprAgreement}
                            onChange={(e) => setDetailedFormData({ ...detailedFormData, gdprAgreement: e.target.checked })}
                            className="mr-2"
                            required
                          />
                          <label htmlFor="gdprAgreement">
                            I agree to the processing of my personal data in accordance with the GDPR.
                          </label>
                        </div>
                      </div>
                      <div className="flex justify-end space-x-4">
                        <button
                          type="button"
                          onClick={() => setShowDetailedReportForm(false)}
                          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 flex items-center"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              <FaSpinner className="animate-spin mr-2" />
                              Submitting...
                            </>
                          ) : (
                            'Submit'
                          )}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
              <div className="w-full md:w-1/2 flex flex-col items-center justify-center text-center mt-6 md:mt-0 px-4 h-full">
                <img src="https://flowserve.widen.net/content/hbfmamarrs/webp/EAP_Logo.webp" alt="Energy Advantage Program Logo" width="200" height="200" />
                <h3 className="text-lg font-bold mt-4">PUT OUR ENGINEERING EXPERTISE TO WORK FOR YOUR OPERATION</h3>
                <p>Receive a detailed energy savings estimate report and associated business case for your site.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default CalculatorForm;

function formatNumber(value) {
  if (typeof value !== 'string') {
    value = value.toString();
  }
  let num = parseInt(value.replace(/,/g, ''), 10);
  if (isNaN(num)) {
    return '----';
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + ' M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + ' K';
  }
  return num.toString();
}
